import { z } from 'zod';

import { publicEnv } from '~/features/environment/public';
import { gql } from '~/utils/gql';

export const enrollmentStateSchema = z.enum([
  'ENROLLED',
  'UNENROLLED',
  'STATIC_ACCESS',
  'PAUSED',
  'SUSPENDED',
  'GRADUATED',
]);

export const projectStateSchema = z.enum(['unsubmitted', 'submitted', 'completed', 'failed']).nullable();

export const enrollmentBaseSchema = z.object({
  id: z.string(),
  key: z.string(),
  locale: z.string(),
  state: enrollmentStateSchema,
  is_graduated: z.boolean(),
  is_ready_for_graduation: z.boolean(),
  graduation_date: z.string().nullable(),
  learning_plans: z
    .array(
      z.object({
        id: z.string(),
        key: z.string(),
        shortTitle: z.string(),
      })
    )
    .nullable(),
});

export const lessonEnrollmentSchema = z
  .object({
    key: z.string(),
    is_project_lesson: z.boolean(),
    project: z
      .object({
        project_state: z.object({
          state: projectStateSchema,
        }),
      })
      .nullable(),
  })
  .transform((data) => {
    return {
      key: data.key,
      isProjectLesson: data.is_project_lesson,
      projectState: data.project ? data.project.project_state.state : null,
    };
  });

export const nanodegreeEnrollmentSchema = enrollmentBaseSchema
  .extend({
    root_node: z.object({
      semantic_type: z.literal('Degree'),
      title: z.string(),
      version: z.string(),
      project_deadlines: z
        .array(
          z.object({
            progress_key: z.string(),
            due_at: z.string(),
          })
        )
        .nullable()
        .optional(),
      parts: z.array(
        z.object({
          key: z.string(),
          title: z.string(),
          modules: z.array(
            z.object({
              lessons: z.array(lessonEnrollmentSchema),
            })
          ),
        })
      ),
      cohorts: z.array(z.object({ id: z.number().nullable() }).nullable()).nullable(),
    }),
  })
  .transform((data) => {
    const classroomLink = `${publicEnv.NEXT_PUBLIC_LEARN_URL}/nanodegrees/${data.key}`;

    const programParts = [];
    const programLessons = [];

    for (const part of data.root_node.parts) {
      const partLessons = getPartLessons(part, classroomLink, true);

      programParts.push({
        key: part.key,
        title: part.title,
      });

      programLessons.push(...partLessons);
    }

    return {
      ...transformEnrollmentBase(data),
      semanticType: data.root_node.semantic_type,
      title: data.root_node.title,
      version: data.root_node.version,
      programLessons,
      programParts,
      classroomLink,
      registrarCohortId: (data.root_node.cohorts || [])[0]?.id ?? null,
      projectDeadlines: data.root_node.project_deadlines
        ? data.root_node.project_deadlines.map((deadline) => ({
            progressKey: deadline.progress_key,
            dueAt: deadline.due_at,
          }))
        : [],
    };
  });

export const partEnrollmentSchema = enrollmentBaseSchema
  .extend({
    root_node: z.object({
      semantic_type: z.literal('Part'),
      key: z.string(),
      version: z.string(),
      title: z.string(),
      modules: z.array(
        z.object({
          lessons: z.array(lessonEnrollmentSchema),
        })
      ),
    }),
  })
  .transform((data) => {
    const classroomLink = `${publicEnv.NEXT_PUBLIC_LEARN_URL}/paid-courses/${data.key}`;
    const programLessons = getPartLessons(data.root_node, classroomLink, false);

    return {
      ...transformEnrollmentBase(data),
      semanticType: data.root_node.semantic_type,
      title: data.root_node.title,
      version: data.root_node.version,
      programLessons,
      classroomLink,
    };
  });

export const courseEnrollmentSchema = enrollmentBaseSchema
  .extend({
    root_node: z.object({
      semantic_type: z.literal('Course'),
      title: z.string(),
      version: z.string(),
      lessons: z.array(lessonEnrollmentSchema),
    }),
  })
  .transform((data) => {
    const classroomLink = `${publicEnv.NEXT_PUBLIC_LEARN_URL}/courses/${data.key}`;

    const programLessons = [];

    for (const lesson of data.root_node.lessons) {
      programLessons.push({ ...lesson, classroomLink: `${classroomLink}/lessons/${lesson.key}` });
    }

    return {
      ...transformEnrollmentBase(data),
      semanticType: data.root_node.semantic_type,
      title: data.root_node.title,
      version: data.root_node.version,
      programLessons,
      classroomLink,
    };
  });

export const enrollmentContentSchema = z.union([
  nanodegreeEnrollmentSchema,
  partEnrollmentSchema,
  courseEnrollmentSchema,
]);

export const enrollmentsSchema = z
  .object({
    data: z.object({
      user: z.object({
        enrollments: z.array(enrollmentContentSchema).nullable(),
      }),
    }),
  })
  .transform(({ data }) => data.user.enrollments);

export const enrollmentSchema = z
  .object({
    data: z.object({
      user: z.object({
        enrollment: enrollmentContentSchema.nullable(),
      }),
    }),
  })
  .transform(({ data }) => data.user.enrollment);

export type EnrollmentBase = z.infer<typeof enrollmentBaseSchema>;
export type Enrollments = z.infer<typeof enrollmentsSchema>;
export type Enrollment = z.infer<typeof enrollmentSchema>;
export type CourseEnrollment = z.infer<typeof courseEnrollmentSchema>;
export type NanodegreeEnrollment = z.infer<typeof nanodegreeEnrollmentSchema>;
export type PartEnrollment = z.infer<typeof partEnrollmentSchema>;
export type EnrollmentsResponse = z.input<typeof enrollmentsSchema>;
export type EnrollmentResponse = z.input<typeof enrollmentSchema>;

function transformEnrollmentBase(data: EnrollmentBase) {
  return {
    id: data.id,
    key: data.key,
    locale: data.locale,
    state: data.state,
    isGraduated: data.is_graduated,
    isReadyForGraduation: data.is_ready_for_graduation,
    graduationDate: data.graduation_date,
    learningPlans: data.learning_plans,
  };
}

const enrollmentFragment = gql`
  fragment enrollment on Enrollment {
    id
    key
    locale
    state
    is_graduated
    is_ready_for_graduation
    graduation_date
    root_node {
      key
      version
      semantic_type
      title
      ... on Nanodegree {
        project_deadlines {
          due_at
          progress_key
        }
        parts(filter_by_enrollment_service_model: true) {
          ...partEnrollment
        }
        cohorts {
          id
        }
      }
      ... on Part {
        ...partEnrollment
      }
      ... on Course {
        lessons {
          ...lessonEnrollment
        }
      }
    }
    learning_plans {
      id
      key
      shortTitle
    }
  }

  fragment partEnrollment on Part {
    key
    semantic_type
    title
    modules {
      lessons {
        ...lessonEnrollment
      }
    }
  }

  fragment lessonEnrollment on Lesson {
    key
    is_project_lesson
    project {
      project_state {
        state
      }
    }
  }
`;

export const fetchEnrollmentsQuery = gql`
  query FetchEnrollments($userId: String) {
    user(id: $userId) {
      enrollments(order: [last_viewed_at], states: [ENROLLED, GRADUATED, STATIC_ACCESS, PAUSED]) {
        ...enrollment
      }
    }
  }

  ${enrollmentFragment}
`;

export const fetchEnrollmentQuery = gql`
  query FetchEnrollment($userId: String, $key: String!) {
    user(id: $userId) {
      enrollment(node_key: $key) {
        ...enrollment
      }
    }
  }

  ${enrollmentFragment}
`;

function getPartLessons(part: any, baseClassroomLink: string, isNanodegree: boolean) {
  const programLessons = [];

  for (const m of part.modules) {
    for (const lesson of m.lessons) {
      programLessons.push({
        ...lesson,
        classroomLink: isNanodegree
          ? `${baseClassroomLink}/parts/${part.key}/lessons/${lesson.key}`
          : `${baseClassroomLink}/lessons/${lesson.key}`,
      });
    }
  }

  return programLessons;
}
